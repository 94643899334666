<template>
  <div>
    <titleLink title="风险地区" :onClick="onClickBack"></titleLink>
    <div style="padding:.3rem .2rem 3rem .3rem;height:100vh;overflow:hidden;overflow-y:scroll">
      <card>
        <template #content>
          <div>
            <div class="select-bg" @click="showAreaSelect">
              <span class="main-txt bold">{{ori_province}}&nbsp;{{ori_city}}</span>
              <van-icon name="arrow-down" size="15" style="margin-left:.2rem"/>
            </div>
            <van-row style="text-algn: center" 
              v-if="picked_risk_area.highlist.length == 0 && picked_risk_area.midlist.length == 0">
              <van-empty
                image="https://img1.dxycdn.com/2021/0805/905/4694032400806346943-22.png"
                description="全部低风险"
                class="custom-image"
              />
            </van-row>
            <van-row v-if="picked_risk_area.highlist.length > 0" style="margin-top:.2rem">
              <van-col span="10" class="main-txt">
                <van-icon name="bell" size="15" color="#f86565"/>
                高风险地区{{picked_risk_area.highlist.length}}个 
              </van-col>
              <van-col span="14" class="info-txt">
                更新截至：{{data.end_update_time}} 
              </van-col>
            </van-row>
            <van-row v-for="city, idx in picked_risk_area.highlist" :key="'ph' + idx" 
              class="info-bg info-box" style="line-height:15px;margin-top:.2rem;padding-bottom: 0.3rem;">
              <van-col span="8">
                <span class="main-txt">{{city.city}}</span>
              </van-col>
              <van-col span="16">
                <van-row v-for="community, cidx in city.communitys" :key="'ch' + cidx">
                  <span class="main-txt">{{city.county}}{{community}}</span>
                </van-row>
              </van-col>
            </van-row>
            <van-row v-if="picked_risk_area.midlist.length > 0" style="margin-top:.2rem">
              <van-col span="10" class="main-txt">
                <van-icon name="bell" size="15" color="#fbb046"/>
                中风险地区{{picked_risk_area.midlist.length}}个 
              </van-col>
              <van-col span="14" class="info-txt">
                更新截至：{{data.end_update_time}} 
              </van-col>
            </van-row>
            <van-row v-for="city, idx in picked_risk_area.midlist" :key="'pm' + idx" 
              class="info-bg info-box" style="line-height:15px;margin-top:.2rem;padding-bottom: 0.3rem;">
              <van-col span="8">
                <span class="main-txt">{{city.city}}</span>
              </van-col>
              <van-col span="16">
                <van-row v-for="community, cidx in city.communitys" :key="'ch' + cidx">
                  <span class="main-txt">{{city.county}}{{community}}</span>
                </van-row>
              </van-col>
            </van-row>
          </div>
        </template>
      </card>
      <card style="margin-top:.2rem">
        <template #content>
          <van-row style="line-height:20px">
            <span class="main-txt bold">全国风险区域汇总</span>
          </van-row>
          <van-row class="main-txt" style="margin-top: 0.4rem;">
            <van-col span="10">
              <van-icon name="bell" size="15" color="#f86565"/>
              高风险地区{{data.hcount}}个
            </van-col>
            <van-col span="14" class="info-txt">
              更新截至：{{data.end_update_time}}
            </van-col>
            <van-col span="24" v-for="province, idx in highmapKeys" :key="'h' + idx" style="margin-top:.2rem">
              <van-row gutter="8" type="flex">
                <van-col span="2">
                  <div class="info-box high-risk center">
                    {{province}}
                  </div>
                </van-col>
                <van-col span="22" class="info-box info-bg">
                  <van-row v-for="item, index in highmap[province]" :key="'ma' + index">
                    <div>
                      <van-row type="flex" v-for="community, cidx in item.communitys" :key="'c'+cidx">
                        <van-col span="8">{{item.city}}</van-col>
                        <van-col span="16">{{item.county}}{{community}}</van-col>
                      </van-row>
                    </div>
                  </van-row>
                </van-col>
              </van-row>
            </van-col>
          </van-row>
          <van-row class="main-txt" style="margin-top: 0.4rem;">
            <van-col span="10">
              <van-icon name="bell" size="15" color="#fbb046"/>
              中风险地区{{data.mcount}}个
            </van-col>
            <van-col span="14" class="info-txt">
              更新截至：{{data.end_update_time}}
            </van-col>
            <van-col span="24" v-for="province, idx in midmapKeys" :key="'m' + idx" style="margin-top:.2rem">
              <van-row gutter="8" type="flex">
                <van-col span="2">
                  <div class="info-box mid-risk center">
                    {{province}}
                  </div>
                </van-col>
                <van-col span="22" class="info-box info-bg">
                  <van-row v-for="item, index in midmap[province]" :key="'ma' + index">
                    <div>
                      <van-row type="flex" v-for="community, cidx in item.communitys" :key="'c'+cidx">
                        <van-col span="8">{{item.city}}</van-col>
                        <van-col span="16">{{item.county}}{{community}}</van-col>
                      </van-row>
                    </div>
                  </van-row>
                </van-col>
              </van-row>
            </van-col>
          </van-row>
        </template>
      </card>
      <van-action-sheet  v-model="showArea">
        <van-area
          title="选择区域"
          :area-list="areaList" columns-num="2"
          :columns-placeholder="['请选择', '请选择']"
          @confirm="showAreaRisk"
        />
      </van-action-sheet>
    </div>
  </div>
</template>

<script>
import card from "@/views/components/Card";
import titleLink from "@/views/components/TitleLink";
import { areaList } from '@vant/area-data';
import api from '@/services/apis'
export default {
  components: {
    card, titleLink
  },
  data() {
    return {
      data: {
        highlist: [],
        middlelist: [],
        end_update_time: "",
        hcount: 0,
        mcount: 0
      },
      highmap: {},
      ori_province: "",
      ori_city: "",
      city: "",
      province: "",
      midmap: {},
      highmapKeys: [],
      midmapKeys: [],
      areaList: areaList,
      showArea: false,
      picked_risk_area: {
        highlist: [],
        midlist: []
      }
    }
  },
  mounted() {
    this.ori_province = this.$route.query.province
    this.ori_city = this.$route.query.city
    this.province = this.cleanProvince(this.ori_province)
    this.city = this.ori_city.replace('市', '')

    api.yiqing_info().then(res => {
      this.data = res.data.ncov3.data
      this.data.highlist.forEach(ele => {
        const name = this.cleanProvince(ele.province)
        if(!this.highmap[name]) {
          this.highmap[name] = []
          this.highmapKeys.push(name)
        }
        this.highmap[name].push(ele)
      })
      this.data.middlelist.forEach(ele => {
        const name = this.cleanProvince(ele.province)
        if(!this.midmap[name]) {
          this.midmap[name] = []
          this.midmapKeys.push(name)
        }
        this.midmap[name].push(ele)
      })
      this.showAreaRisk([
        {name: this.ori_province},
        {name: this.ori_city}
      ])
    })
  },
  methods: {
    onClickBack() {
      this.$router.push('/yiqing/main')
    },
    showAreaRisk(data) {
      this.picked_risk_area.highlist = []
      this.picked_risk_area.midlist = []
      if(data[0].name != '' && data[1].name != '') {
        this.ori_province = data[0].name
        this.ori_city = data[1].name
        this.province = this.cleanProvince(this.ori_province)
        this.showArea = false

        if(this.highmap[this.province]) {
          this.highmap[this.province].forEach(ele => {
            if(ele.province == this.ori_province && ele.city == this.ori_city
             || this.isVipCity(this.ori_city)) {
              this.picked_risk_area.highlist.push(ele)
            }
          })
        }
        if(this.midmap[this.province]) {
          this.midmap[this.province].forEach(ele => {
            if(ele.province == this.ori_province && ele.city == this.ori_city
              || this.isVipCity(this.ori_city)) {
              this.picked_risk_area.midlist.push(ele)
            }
          })
        }
      }
    },
    showAreaSelect() {
      this.showArea = true
    },
    isVipCity(city) {
      return city == '北京市' || city == '天津市' || city == '上海市' || city == '重庆市'
    },
    cleanProvince(name) {
      if(name.indexOf('新疆') > -1) {
        return '新疆'
      }
      if(name.indexOf('西藏') > -1) {
        return '西藏'
      }
      if(name.indexOf('内蒙古') > -1) {
        return '内蒙古'
      }
      if(name.indexOf('广西') > -1) {
        return '广西'
      }
      if(name.indexOf('宁夏') > -1) {
        return '宁夏'
      }
      if(name.indexOf('香港') > -1) {
        return '香港'
      }
      if(name.indexOf('澳门') > -1) {
        return '澳门'
      }
      return name.replace('省', '')
    }
  }
}
</script>

<style scoped>
.main-txt {
  font-size: 15px;
}
.info-txt {
  color: #666666;
  font-size: 12px;
}
.bold {
  font-weight: bold;
}
.info-box {
  border-radius: 5px;
}
.high-risk {
  background: rgba(255,91,77,.1);
  padding: .1rem .1rem .1rem .08rem;
}
.mid-risk {
  background: rgba(255,170,0,.1);
  padding: .1rem .1rem .1rem .08rem;
}
.info-bg {
  background: #f7f7f7;
  padding: .1rem;
}
.center {
  text-align: center;
}
.select-bg {
  background: #f7f7f7;
  border-radius: 10px;
  text-align: center;
  height: 30px;
  line-height: 15px;
}
::v-deep .custom-image .van-empty__image {
    width: 90px;
    height: 90px;
}
</style>